import React from 'react';

const Home = () => {
	return (
		<div>
            <p>Welcome to Homebrew Heroes, the game club that embraces gaming's diversity. 
                From indie gems to classics, we explore genres and uncover unique experiences. 
                Join fellow gamers in fostering discussions that span all aspects of 
                gaming—mechanics, narratives, and strategies. Let's share perspectives, 
                celebrate creativity, and embark on an inclusive gaming adventure together!</p>
            <h2>Current Game</h2>
            <div class="Parent1">
                <div class="child1">
                    <a href='https://store.steampowered.com/app/553420/TUNIC/' 
                    target='_blank' rel="noreferrer">
                        <img src="banner_tunic.png" alt="Tunic"></img></a>
                 </div>
                <div class="child2">
                <h3>October 6, 2023 (Tentative)
                    <br></br>
                    <br></br>
                    8 PM EST
                </h3>
                </div>
            </div>
            {/* <h2>Past Games</h2>
            <div class="Parent2">
                <div class="child3">
                    <a href='https://store.steampowered.com/app/553420/TUNIC/' 
                    target='_blank' rel="noreferrer">
                        <img src="banner_tunic.png" alt="Tunic"></img></a>
                 </div>
                <div class="child4">
                    <h3>August 10, 2023</h3>
                </div>
                <div class="child5">
                <iframe
                    title='Youtube'
                    src="https://www.youtube.com/embed/tgbNymZ7vqY">
                </iframe>
                </div>
            </div> */}
		</div>
	);
};

export default Home;
