import './App.css';
import Footer from './components/Footer';
import Navbar from './components/Navbar';
import { BrowserRouter as Router, Routes, Route }
    from 'react-router-dom';
import Home from './pages';
import About from './pages/about';
import Coop from './pages/coop';
import UnderConstruction from './pages/underConstruction';
import Twitch from './components/Stream';
import Steam from './components/Steam';

function App() {
  return (
    <div className="App">
      <Router>
        <Navbar />
          <Routes>
              <Route exact path='/' element={<Home />} />
              <Route path='/underConstruction' element={<UnderConstruction />} />
              <Route path='/about' element={<About />} />
              <Route path='/coop' element={<Coop />} />
              <Route path='/steam' element={<Steam />} />
          </Routes>
        <Twitch />
      <Footer />
      </Router>
    </div>
  );
}

export default App;
