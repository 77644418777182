import React from "react";
import { Nav, NavLink, NavMenu, NavImg, NavIcon }
	from "./NavbarElements";

const Navbar = () => {
	return (
		<>
			<Nav>
				<NavIcon>
                    <NavLink to="/" activeStyle>
						<NavImg src="./icon_hh.png" ></NavImg>
					</NavLink>
				</NavIcon>
				<NavMenu>
					<NavLink to="/about" activeStyle>
						About
					</NavLink>
					<NavLink to="/coop" activeStyle>
						Co-op
					</NavLink>
                    <NavLink to="/underConstruction" activeStyle>
						Builds
					</NavLink>
                    <NavLink to="/underConstruction" activeStyle>
						Schedule
					</NavLink>
				</NavMenu>
			</Nav>
		</>
	);
};

export default Navbar;
