import React from "react";
import { IFrame } from './StreamElements';

const Twitch = () => {
	return (
		<>
            <IFrame
                src="https://player.twitch.tv/?channel=pulse_hh&parent=homebrewheroes.dev"
                height="<height>"
                width="<width>"
                allowfullscreen>
            </IFrame>
		</>
	);
};

export default Twitch;
