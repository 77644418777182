import React from 'react';

const Coop = () => {
	return (
		<>
			<div className="App-body">
				<div>
					<p>
						In October 3rd, 2017, Inqindi and I embarked on an exciting journey dedicated to conquering
						cooperative games spanning various genres and challenges. This page will serve as a record to that
						journey. Our motto is 'Blind Playthroughs: Minimum Knowledge, Maximum Difficulty'.
					</p>
				</div>
				<div class="Parent1">
					<div class="child1">
						<br/>
						<a href='https://store.steampowered.com/app/774801/Crab_Champions/'
						target='_blank' rel="noreferrer">
							<img src="current_game.png" alt="currentGame"></img></a>
					 </div>
					<div class="child2">
					<h3>
						<br/><br/>
						&#8592; Current game
						<br/><br/>
						Every Tuesday on Twitch
						<br></br>
						<br></br>
						8 PM EST
					</h3>
					</div>
				</div>
				<div class="Ranking">
					<div>
						<h2>Pulse's Rankings</h2>
						<ul>
							<li>It Takes Two</li>
							<li>Divinity: Original Sin 2</li>
							<li>Grounded (EA)</li>
							<li>Overcooked 2</li>
							<li>Remnant 2</li>
							<li>Remnant: From the Ashes</li>
							<li>Tiny Tina's Wonderlands (w/o Chaos)</li>
							<li>Ashen</li>
							<li>Overcooked</li>
							<li>A Way Out</li>
							<li>Shadow Warrior 2</li>
							<li>Full Metal Furies</li>
							<li>Darksiders Genesis</li>
							<li>Trine 4: The Nightmare Prince</li>
							<li>Borderlands 3</li>
							<li>Nine Parchments</li>
							<li>Children of Morta</li>
							<li>Wizard with a Gun</li>
							<li>Magicka 2</li>
							<li>Sniper Elite 5</li>
							<hr/>
							<li>Raft (has potential)</li>
							<li>The Ascent</li>
							<li>Helldivers</li>
							<hr/>
							<li>Diablo 4 (story)</li>
						</ul>
					</div>
					<div>
						<h2>Inqindi's Rankings</h2>
						<ul>
							<li>It Takes Two</li>
							<li>Divinity: Original Sin 2</li>
							<li>Grounded (EA)</li>
							<li>Overcooked 2</li>
							<li>Tiny Tina's Wonderlands (w/o Chaos)</li>
							<li>Remnant: From the Ashes</li>
							<li>Ashen</li>
							<li>Overcooked</li>
							<li>A Way Out</li>
							<li>Trine 4: The Nightmare Prince</li>
							<li>Full Metal Furies</li>
							<li>Darksiders Genesis</li>
							<li>Borderlands 3</li>
							<li>Shadow Warrior 2</li>
							<li>Nine Parchments</li>
							<li>Children of Morta</li>
							<li>Magicka 2</li>
							<li>Raft</li>
							<li>Sniper Elite 5</li>
							<li>The Ascent</li>
							<hr/>
							<li>Helldivers</li>
							<li>Diablo 4 (story)</li>
						</ul>
					</div>
				</div>
			</div>
		</>
	);
};

export default Coop;
