import React from "react";

const UnderConstruction = () => {
	return (
		<div>
            <h1> Under Construction</h1>
            <img src="./image_under_construction.png" class="underConstruction" alt="under construction"></img>
		</div>
	);
};

export default UnderConstruction;
