import styled from 'styled-components';

export const Box = styled.div`
bottom: 0;
width: 100%;
z-index: 2;
background-color: #333;
position: absolute;
// @media (max-width: 1000px) {
// 	padding: 70px 30px;
// }
`;

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	max-width: 1000px;
	margin: 0 auto;
	/* background: red; */
`;

export const Column = styled.div`
display: flex;
flex-direction: column;
text-align: left;
padding: 0.5rem 1rem;
@media (max-width: 1000px) {
	padding: 0rem 0rem;
}
`;

export const Row = styled.div`
display: grid;
grid-template-columns: repeat(auto-fill,
						minmax(185px, 1fr));
grid-gap: 20px;

@media (max-width: 1000px) {
	grid-template-columns: repeat(auto-fill,
						minmax(200px, 1fr));
}
`;

export const Heading = styled.p`
font-size: 24px;
color: #fff;
margin-bottom: 32px;
font-weight: bold;
`;

export const FooterLink = styled.a`
color: #fff;
margin-bottom: 16px;
font-size: 18px;
text-decoration: none;

&:hover {
	color: green;
	transition: 200ms ease-in;
}
`;

export const Img = styled.img`
display: block;
margin-left: auto;
margin-right: auto;
width: 32px;
`;