import React from "react";
import { Box, Container, Row, Column, FooterLink, Img, } from "./FooterStyles";

const Footer = () => {
return (
	<Box>
	<Container>
		<Row>
		<Column>
            <FooterLink href="https://discord.gg/nsCuUtyKQb" target="_blank">
                <Img src="icon_discord.png"></Img>
            </FooterLink>
		</Column>
		<Column>
            <FooterLink href="https://www.youtube.com/channel/UCis-Eu0RK7xRqf83AYgzD8w" target="_blank">
                <Img src="icon_youtube.png"></Img>
            </FooterLink>
		</Column>
		<Column>
            <FooterLink href="https://www.twitch.tv/pulse_hh" target="_blank">
                <Img src="icon_twitch.png"></Img>
            </FooterLink>
		</Column>
		<Column>
            <FooterLink href="https://pulseprojects.dev/" target="_blank">
                <Img src="icon_projects.png"></Img>
            </FooterLink>
		</Column>
		</Row>
	</Container>
	</Box>
);
};
export default Footer;
