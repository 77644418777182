import styled from "styled-components";

export const IFrame = styled.iframe`
width:max-content;
aspect-ratio: 2/1;
position: absolute;
bottom: 10%;
right: 0;
z-index: 10;
`;
