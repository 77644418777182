import React from "react";

const About = () => {
	return (
		<body className="App-body">
            {/* <header className="App-header"></header> */}
                <p>  
					Welcome to Homebrew Heroes, a dynamic game club dedicated to exploring an eclectic range of gaming genres! Our mission is to dive deep into the vast gaming landscape, from indie gems to established classics, and uncover the diverse experiences that each genre has to offer.
				</p>
				<p>
					At Homebrew Heroes, we're all about embracing the variety that gaming has to offer. Whether you're a fan of action-packed shooters, immersive role-playing adventures, strategic simulations, or even indie masterpieces, you'll find a welcoming community of fellow gamers who share your passion.
					</p>
				<p>
					What makes Homebrew Heroes special is our commitment to fostering discussions that span the entire spectrum of gaming. From analyzing gameplay mechanics and narrative depth to sharing strategies and personal anecdotes, our members come together to celebrate the art and enjoyment of gaming in all its forms.
				</p>
				<p>
					Gaming is more than just a hobby—it's a journey of discovery, a canvas for creativity, and a way to connect with others who share our interests. Join us at Homebrew Heroes as we explore different gaming worlds, share our diverse perspectives, and create an inclusive space where every gamer can find something to love.
				</p>
				<p>
					Whether you're drawn to the latest indie titles, classic hits, or everything in between, you're invited to be a part of our community of Homebrew Heroes. Let's embark on this exciting gaming adventure together!
				</p>  
		</body>
	);
};

export default About;
